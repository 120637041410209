'use client'

import styled from '@emotion/styled'
import { DisplayText, Paragraph, Spacer } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import { currentBrand } from '../../../config'
import { NotFoundIllustration } from '../../../assets/illustrations/qasa/not-found'
import { LinkButtonV2 } from '../../../ui-shared/_core/link-button-v2'

const ContentContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: `${theme.spacing['16x']} ${theme.spacing['8x']}`,
}))

const IllustrationContainer = styled.div(({ theme }) => ({
  width: '100%',
  height: theme.sizes['24x'],
  display: 'flex',
  // Without this it's not centered in Firefox for some reason
  justifyContent: 'center',
  [theme.mediaQueries.smUp]: {
    height: theme.sizes[144],
  },
  [theme.mediaQueries.mdUp]: {
    height: theme.sizes['192'],
  },
}))

export default function NotFound() {
  const { t } = useTranslation('page_not_found')

  return (
    <ContentContainer>
      {currentBrand !== 'blocket' && (
        <IllustrationContainer>
          <NotFoundIllustration />
        </IllustrationContainer>
      )}
      <Spacer size="12x" />
      <DisplayText size={{ base: '3xl', sm: '2xl', md: '3xl' }} as="h1">
        {'404'}
      </DisplayText>
      <Spacer size="5x" />
      <Paragraph textAlign="center" size="xl">
        {t([`message_${currentBrand}`, 'message'])}
      </Paragraph>
      <Spacer size="12x" />
      <LinkButtonV2 size="lg" href="/">
        {t('go_to_home')}
      </LinkButtonV2>
    </ContentContainer>
  )
}
